import React from "react";
import * as S from "./Button.sc";

/**
 * Button component
 * @function Button
 * @param {Object} props
 * @param {"border" | "gradient" | "default"} props.type
 * @param {"blue" | "green" | "red" | "black" | "white"} props.color
 * @param {function} props.onClickFunction - action function
 *
 */

const Button = ({
  type,
  color,
  onClickFunction,
  children,
  customStyle,
  className,
  disabled = false,
}) => {
  if (type === "gradient") {
    return (
      <S.GradientButton
        className={className}
        type={type}
        color={color}
        onClick={onClickFunction}
        customStyle={customStyle}
        disabled={disabled}
      >
        {children}
      </S.GradientButton>
    );
  }
  return (
    <S.StyledButton
      className={className}
      type={type}
      color={color}
      onClick={onClickFunction}
      customStyle={customStyle}
      disabled={disabled}
    >
      {children}
    </S.StyledButton>
  );
};

export default Button;
