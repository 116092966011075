import styled, { css } from "styled-components";

const colors = {
  blue: "#4D75E1",
  green: "#339D37",
  red: "#E6141B",
  black: "#1D1D1D",
  white: "#FFFFFF",
};

const gradiendColors = {
  blue: ["#4D75E1", "#4762AB"],
  green: ["#48B44D", "#339D37"],
  red: ["#E6141B", "#F24D53"],
  black: ["#1D1D1D", "#555555"],
  white: ["#FFFFFF", "#DCDCDC"],
};

const buttonStyles = css`
  display: inline-block;
  margin-top: 40px;
  border: ${(props) =>
    props.type === "border" ? `1px solid ${colors[props.color]}` : "none"};
  border-radius: 8px;
  font-size: 16px;
  line-height: 1;
  padding: 16px 36px;
  min-width: 200px;
  color: ${(props) => {
    if (props.type === "border") {
      return colors[props.color];
    } else if (props.color === "white") {
      return "#000";
    } else {
      return "#fff";
    }
  }};
  cursor: pointer;
  background-color: ${(props) =>
    props.type === "border" ? "transparent" : colors[props.color]};
  transition: all 0.2s ease-in;
  text-transform: uppercase;
  text-align: center;
  ${(props) => props.customStyle}
  &:hover {
    opacity: 0.8;
  }
`;

export const StyledButton = styled.button`
  ${buttonStyles}
`;

export const StyledButtonHref = styled.span`
  ${buttonStyles}
`;

export const GradientButton = styled(StyledButton)`
  background: linear-gradient(
    152deg,
    ${(props) => gradiendColors[props.color][0]} 0%,
    ${(props) => gradiendColors[props.color][1]} 100%
  );
`;

export const GradientButtonHref = styled(StyledButtonHref)`
  background: linear-gradient(
    152deg,
    ${(props) => gradiendColors[props.color][0]} 0%,
    ${(props) => gradiendColors[props.color][1]} 100%
  );
`;
