import { useRouter } from "next/router";
import { createContext, useCallback, useEffect, useMemo, useRef } from "react";
import { main } from "./AdHelpers";

export const context = createContext({});

/**
 * Component holds listeners for updating adocean widgets on route change.
 * It is important to batch multiple placements and refresh them once to avoid blinking and unnecessary requests.
 */
export const AdoSubscriber = ({ children }) => {
  const router = useRouter();

  /**
   * Represents a set of subscribed ads with specified placementId. We batch them to invoke ado.refresh only once for specified placementId.
   */
  const subscribes = useRef({});

  const initialMount = useRef(true);

  const pathname = router.pathname;
  useEffect(() => {
    if (initialMount.current) return;
    const placementIdsToRefresh = Object.keys(subscribes.current).filter(
      (key) => subscribes.current[key] > 0
    );
    placementIdsToRefresh.forEach((placementId) => {
      ado.refresh(placementId);
    });
  }, [pathname]);

  useEffect(() => {
    initialMount.current = false;
  }, []);

  const subscribe = useCallback((placementId, slaveId) => {
    const isInitialized =
      window.adocean_slaves.indexOf(`${placementId} ${slaveId}`) > -1;
    if (!isInitialized) {
      main(slaveId, placementId);
    }
    if (subscribes.current[placementId]) {
      subscribes.current[placementId] += 1;
    } else {
      subscribes.current[placementId] = 1;
    }
  }, []);

  const unsubscribe = useCallback((placementId) => {
    subscribes.current[placementId] -= 1;
  }, []);

  const value = useMemo(
    () => ({
      subscribe,
      unsubscribe,
    }),
    [subscribe, unsubscribe]
  );
  return <context.Provider value={value}>{children}</context.Provider>;
};
