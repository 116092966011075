export function add_events(params, fx) {
  params["onServerEmission"] = fx.bind(this, true);
  params["onServerEmissionEmpty"] = fx.bind(this, false);
  return params;
}

export const main = (slave, master) => {
  const mode = "new";
  var key = master + " " + slave;
  // var callback = on_emission.bind(this, key);\
  var callback = () => {};
  ado.config({ mode: mode, xml: false, characterEncoding: true });

  if (ado_null) {
    callback(false);
  } else {
    if (adocean_slaves.indexOf(key) >= 0) {
      console.error("Duplicated ad zone: " + key);
      return;
    }
    adocean_slaves.push(key);

    if (master === undefined) {
      ado.placement(add_events(get_params(slave), callback));
    } else {
      if (adocean_masters.indexOf(master) < 0) {
        adocean_masters.push(master);
        ado.master(get_params(master));
      }
      ado.slave(slave, add_events({ myMaster: master }, callback));
      ado.slave(slave, () => {});
    }
  }
};

export function get_params(id) {
  var params = {
    id: id,
    server: "zniwiarz.topagrar.pl",
    vars: {
      logged: ADOCEAN_VAR_LOGGED,
      url: encodeURIComponent(document.URL),
    },
  };
  if (adocean_article_category >= 0)
    params.vars.category = adocean_article_category;
  adocean_tag.forEach(function (item) {
    params.vars[item.replace("-", "_")] = 1;
  });
  return params;
}
